<template>
  <div>
    <GenericTable
      ref="lista"
      name="Classes de Materiais"
      title="classes de materiais"
      add-route="classeMaterialAdicionar"
      edit-route="classeMaterialEditar"
      :filters="filters"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="classeMaterialRoute"
      :permissionsToWrite="['rw_material']"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Nome</label>
              <GenericSelect
                name="nomeClasse"
                route="classeMaterial"
                labelKey="nome"
                v-model="filters.nome"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericSelect from '../../components/Form/GenericSelect';

export default {
  components: {
    GenericSelect,
  },
  data() {
    return {
      classeMaterialRoute: 'classeMaterial',
      filters: {
        nome: null,
      },
    };
  },
};
</script>
